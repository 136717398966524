function Contact() {
    return <div className="section px-2 px-lg-4 pb-4 pt-5 mb-5" id="contact">
    <div className="container-narrow">
      <div className="text-center mb-5">
        <h2 className="marker marker-center">Contact Me</h2>
      </div>
      <div className="row">
        <div className="col-md-6" data-aos="zoom-in" data-aos-delay="100">
          <div className="bg-light my-2 p-3 pt-2"><form action="https://formspree.io/f/manywqdq"
      method="POST">
      <div className="form-group my-2">
        <label htmlFor="name" className="form-label fw-bolder">Name</label>
        <input className="form-control" type="text" id="name" name="name" required />
      </div>
      <div className="form-group my-2">
        <label htmlFor="email" className="form-label fw-bolder">Email</label>
        <input className="form-control" type="email" id="email" name="_replyto" required />
      </div>
    <div className="form-group my-2">
      <label htmlFor="message" className="form-label fw-bolder">Message</label>
      <textarea className="form-control" style={{resize: "none"}} id="message" name="message" rows={4} required></textarea>
    </div>
    <button className="btn btn-primary mt-2" type="submit">Send</button>
  </form>
          </div>
        </div>
        <div className="col-md-6" data-aos="fade-left" data-aos-delay="300">
          <div className="mt-3 px-1">
            <div className="h5">Let’s talk!</div>
            <p>If you like my work and want to send a feedback then drop me a message using the contact form. </p>
            <p>Or get in touch using my email.</p>
            <p>See you!</p>
          </div>
          <div className="mt-53 px-1">
            <div className="row"> 
              <div className="col-sm-2">
                <div className="pb-1">Email:</div>
              </div>
              <div className="col-sm-10">
                <div className="pb-1 fw-bolder">me@daniepaul.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Contact;