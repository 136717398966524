import {
  faFacebook,
  faFlickr,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Nav } from "react-bootstrap";

function PageCover() {
  return (
    <header>
      <div className="cover bg-light">
        <div className="container px-3">
          <div className="row">
            <div className="col-lg-6 p-2">
              <img
                className="img-fluid"
                data-aos="fade-right"
                data-aos-delay="50"
                src="images/illustrations/manager.svg"
                alt="hello"
              />
            </div>
            <div className="col-lg-6">
              <div className="mt-5">
                <p className="lead text-uppercase mb-1">Hello!</p>
                <h1
                  className="intro-title marker"
                  data-aos="fade-left"
                  data-aos-delay="50"
                >
                  I’m Daniel Jebakumar
                </h1>
                <p
                  className="lead fw-normal mt-3"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Software Engineering Leader | Agile Evangelist | Software
                  Developer<br/><br/>
                  Proven software engineering leader with experience delivering high-quality software solutions. Expertise in Agile methodologies, solution architecture, and leading high-performing engineering teams.
                </p>
                <div
                  className="social-nav"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <Nav>
                    <Nav.Item>
                      <Nav.Link href="https://github.com/daniepaul" target="_blank">
                        <FontAwesomeIcon
                          icon={faGithub}
                          aria-label="Twitter"
                        ></FontAwesomeIcon>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="https://www.facebook.com/daniepaul" target="_blank">
                        <FontAwesomeIcon
                          icon={faFacebook}
                          aria-label="Facebook"
                        ></FontAwesomeIcon>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="https://www.linkedin.com/in/daniepaul" target="_blank">
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          aria-label="LinkedIn"
                        ></FontAwesomeIcon>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="https://www.flickr.com/photos/daniepaul/" target="_blank">
                        <FontAwesomeIcon
                          icon={faFlickr}
                          aria-label="LinkedIn"
                        ></FontAwesomeIcon>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="mt-3" data-aos="fade-up" data-aos-delay="200">
                  <a
                    className="btn btn-primary shadow-sm mt-1 hover-effect"
                    href="#contact"
                  >
                    Get In Touch{" "}
                    <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wave-bg"></div>
    </header>
  );
}

export default PageCover;
