function About() {
  const getAge = () => {
    var today = new Date();
    var birthDate = new Date("1986-01-10");
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const getExperience = () => {
    var today = new Date();
    var startDate = new Date("2007-06-01");
    var experience = today.getFullYear() - startDate.getFullYear();
    var m = today.getMonth() - startDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < startDate.getDate())) {
      experience--;
    }
    return experience;
  };

  return (
    <div className="section pt-4 px-3 px-lg-4" id="about">
      <div className="container-narrow">
        <div className="row">
          <div className="col-md-6">
            <h2 className="h4 my-2">Hello! I’m Daniel Jebakumar.</h2>
            <p>
              I am a results-driven Software Engineering Manager with over {getExperience()}{' '}
              years of experience in managing global teams and delivering
              complex software projects. I have proven ability to manage large
              initiatives, lead Agile transformations, and ensure compliance
              with industry security standards. Expertise in full-stack
              development, cloud-native technologies, standardization of UI
              components, and leading Agile transformation, implementing QA
              policy, and coordinating global teams.
            </p>
            <div className="row mt-3">
              <div className="col-sm-2">
                <div className="pb-1">Age:</div>
              </div>
              <div className="col-sm-10">
                <div className="pb-1 fw-bolder">{getAge()}</div>
              </div>
              <div className="col-sm-2">
                <div className="pb-1">Email:</div>
              </div>
              <div className="col-sm-10">
                <div className="pb-1 fw-bolder">me@daniepaul.com</div>
              </div>
              <div className="col-sm-2">
                <div className="pb-1">Location:</div>
              </div>
              <div className="col-sm-10">
                <div className="pb-1 fw-bolder">Irving, USA</div>
              </div>
            </div>
          </div>
          <div
            className="col-md-5 offset-md-1"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            <img
              className="avatar img-fluid mt-2"
              src="images/avatar.jpg"
              width="400"
              height="400"
              alt="Walter Patterson"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
