import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";

function Header() {
  return (
    <header className="bg-light">
      <Navbar
        expand="lg" 
        className="navbar navbar-expand-lg navbar-light bg-light"
        id="header-nav"
        role="navigation"
      >
        <Container>
          <Navbar.Brand className="link-dark site-title mb-0" href="/">
            Daniel Jebakumar.
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarSupportedContent" />
          <Navbar.Collapse id="navbarSupportedContent">
            <Nav className="ms-auto me-2">
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#skills">Skills</Nav.Link>
              <Nav.Link href="#experience">Experience</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
