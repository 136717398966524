import React from "react";

function Footer() {
  return (
    <footer className="pt-4 pb-4 text-center bg-light">
      <div className="container">
        <div className="my-3">
          <div className="h4">Daniel Jebakumar.</div>
          <p>Software Engineering Leader | Agile Evangelist | Software Developer</p>
          <div className="social-nav">
            <nav role="navigation">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://twitter.com/templateflip"
                    title="Twitter"
                  >
                    <i className="fab fa-twitter"></i>
                    <span className="menu-title sr-only">Twitter</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.facebook.com/templateflip"
                    title="Facebook"
                  >
                    <i className="fab fa-facebook"></i>
                    <span className="menu-title sr-only">Facebook</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.instagram.com/templateflip"
                    title="Instagram"
                  >
                    <i className="fab fa-instagram"></i>
                    <span className="menu-title sr-only">Instagram</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.linkedin.com/"
                    title="LinkedIn"
                  >
                    <i className="fab fa-linkedin"></i>
                    <span className="menu-title sr-only">LinkedIn</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.behance.net/templateflip"
                    title="Behance"
                  >
                    <i className="fab fa-behance"></i>
                    <span className="menu-title sr-only">Behance</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="text-small text-secondary">
          <div className="mb-1">&copy; daniepaul.com. All rights reserved.</div>
          <div>
            {/* Make sure to buy a license for the template before removing the line below. Buy license on https://templateflip.com/ */}
            Design -{" "}
            <a href="https://templateflip.com/" target="_blank" rel="noreferrer">
              TemplateFlip
            </a>
          </div>
          <a href="https://www.freepik.com/free-vector/work-progress-concept-illustration_12832654.htm#query=office&position=34&from_view=keyword&track=sph&uuid=8c19f398-633d-440b-baef-e6e626278ba3">Image by storyset on Freepik</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
