function Experience() {
  return (
    <div className="section px-3 px-lg-4 pt-5" id="experience">
      <div className="container-narrow">
        <div className="text-center mb-5">
          <h2 className="marker marker-center">Experience</h2>
        </div>
        <div className="row gx-4 gy-4">
          <div className="col-md-6 d-flex">
            <div
              className="card"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <div className="card-header px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="h5 mb-1">Manager, Software Engineering</h3>
                    <div className="text-muted text-small">
                    Sabre Inc., Southlake, TX, USA <small>(April 2022 – September 2024)</small>
                    </div>
                  </div>
                  <img
                    src="images/clients/sabre.svg"
                    width="60"
                    height="48"
                    alt="Sabre Inc"
                  />
                </div>
              </div>
              <div className="card-body px-3 py-2">
                <p>
                  Proven track record in managing large teams of software engineers 
                  and delivering multi-discipline projects, combined with a strong 
                  ability to drive improvements in processes. 
                  Rich experience in technical leadership, agile methodologies, 
                  and the full software development lifecycle. 
                  Proven experience in effective team management, including 
                  recruitment, induction, and resource allocation.
                </p>
                <p>
                  Expertise in cloud technologies, security and compliance conformity. 
                  Commitment to optimization is proved by the successful work of 
                  standardization of UI development, improvement of software delivery 
                  pipelines, and enhancement of team efficiency and product quality.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="300"
            >
              <div className="card-header px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="h5 mb-1">Lead, Software Engineering</h3>
                    <div className="text-muted text-small">
                    Sabre Inc., Southlake, TX, USA <small>(April 2018 – March 2022)</small>
                    </div>
                  </div>
                  <img
                    src="images/clients/sabre.svg"
                    width="60"
                    height="48"
                    alt="Sabre Inc"
                  />
                </div>
              </div>
              <div className="card-body px-3 py-2">
                <p>
                  Proven to lead and develop high-performing engineering teams in collaborative 
                  environments. Proved successes in managing geographically dispersed teams and 
                  delivering impactful products with high daily usage. Expertise in agile 
                  methodologies; a track record of streamlining development processes, reducing 
                  cycle times by as much as. Strong capabilities in solution design, user 
                  interface design, development, and integration; delivering projects on time 
                  and within budget. Emphasis on innovation to ensure solutions that drastically 
                  reduce resolution times. Excellent communication and stakeholder management 
                  skills, with strong relationship building with clients and colleagues alike. 
                  Proven ability in driving process improvements, including successful migrations 
                  from Waterfall to Agile, which hugely shorten release cycles.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div
              className="card"
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <div className="card-header px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="h5 mb-1">Software Developer Contractor <small>(Sabre Inc.)</small></h3>
                    <div className="text-muted text-small">
                    mRoads Inc., Plano, TX, USA <small>(May 2015 – April 2018)</small>
                    </div>
                  </div>
                  <img
                    src="images/clients/sabre.svg"
                    width="60"
                    height="48"
                    alt="Sabre Inc"
                  />
                </div>
              </div>
              <div className="card-body px-3 py-2">
                <p>
                  Proven experience in leading and managing distributed software engineering teams. 
                  Expertise in agile methodologies and practices of Scrum. Strong comprehension of 
                  solution design and application architecture; deliver projects on time with scope. 
                  Knowledgeable about project planning, resource allocation, and estimation. Led the 
                  architecture design of a very successful white label web application that greatly 
                  increased the rate of feature adoption and reduced production issues. Effective 
                  communication and collaboration skills; nurture relationships with clients and 
                  colleagues at all levels. Led the modernization of a legacy application to a modern 
                  technology stack, resulting in a new product rolled out to numerous clients.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="400"
            >
              <div className="card-header px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="h5 mb-1">Tech. Lead Contractor <small>(Fidelity Investments)</small></h3>
                    <div className="text-muted text-small">
                    Infosys Limited, Plano, TX, USA <small>(August 2010 – May 2015)</small>
                    </div>
                  </div>
                  <img
                    src="images/clients/fidelity.svg"
                    width="60"
                    height="55"
                    alt="Fidelity Investment"
                  />
                </div>
              </div>
              <div className="card-body px-3 py-2">
                <p>
                  Strong foundation in software development with experience in both Scrum and technical 
                  leadership roles. Proven ability to lead project development, application design, testing, 
                  and solution implementation. Able to work with cross-functional teams in gathering user 
                  input and defining requirements. Focus on user experience; hence, creation of intuitive 
                  and user-friendly interfaces. Excellent communication skills enable collaboration 
                  effectively with offshore teams, mentor colleagues, and build strong relationships with 
                  clients. Able to comfortably contribute to pre-sales activities: demonstrates technical 
                  and communication skills.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex offset-md-3">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-delay="400"
            >
              <div className="card-header px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <h3 className="h5 mb-1">Founder and Senior Programmer</h3>
                    <div className="text-muted text-small">
                    Colourz Technologies Pvt Ltd, India <small>(June 2007 – July 2010)</small>
                    </div>
                  </div>
                  <img
                    src="images/clients/colourz.png"
                    width="90"
                    height="45"
                    alt="Colourz"
                  />
                </div>
              </div>
              <div className="card-body px-3 py-2">
                <p>
                  Demonstrated success in moving through the complex process of the startup journey 
                  with vision, product development, business acumen, and leadership. Found ways to 
                  identify unmet needs in the market, develop innovative solutions for clients. 
                  Experienced in business planning for financial management, marketing, and growth. 
                  Can build and lead high-performance teams, adapt to challenges, and leverage networks 
                  toward the achievement of strategic objectives.
                </p>
                <p>
                  Proficiency in all stages of the software development lifecycle, encompassing 
                  coding, design, testing, and impact analysis. Capable of contributing to pre-sales activities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
