function Skills() {
  return (
    <div className="section px-3 px-lg-4 py-5 bg-light my-4" id="skills">
      <div className="container-narrow">
        <div className="text-center mb-5">
          <h2 className="marker marker-center">My Skills</h2>
        </div>
        <div className="text-center">
          <p className="mx-auto mb-3" style={{ maxWidth: "600px" }}>
            I am a quick learner and specialize in multitude of skills required
            for a hands-on software engineering leader.
          </p>
        </div>
        <div className="row py-3">
          <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="100"> 
            <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src="images/services/web-design.svg" width="96" height="96" alt="Leadership and Mentorship"/></div>
            <div className="bg-white border rounded p-3 mt-n4">
              <div className="h5 mt-3">Leadership and Mentorship</div>
              <p className="text-small">Led and mentored high-performing engineering teams, fostering collaborative environments and driving successful project outcomes.</p>
            </div>
          </div>
          <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="200">
            <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src="images/services/graphic-design.svg" width="96" height="96" alt="Technical Proficiency"/></div>
            <div className="bg-white border rounded p-3 mt-n4">
              <div className="h5 mt-3">Technical Proficiency</div>
              <p className="text-small">Strong command of various programming languages, frameworks, and technologies, including JavaScript, React, Angular, cloud platforms and databases.</p>
            </div>
          </div>
          <div className="col-md-4 text-center mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="bg-white border rounded-circle d-inline-block p-2"><img className="p-2" src="images/services/ui-ux.svg" width="96" height="96" alt="ui-ux"/></div>
            <div className="bg-white border rounded p-3 mt-n4">
              <div className="h5 mt-3">Agile and Process Improvement</div>
              <p className="text-small">Have deep understanding and practical application of Agile methodologies and with proven track record of streamlining workflows and improving velocity.</p>
            </div>
          </div>
        </div>
        <div className="p-3">
          <div className="row gx-5 gy-3">
            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Leadership</span>
                  <span>80%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                Strategic Thinking, Budgeting, Problem Solving, Crisis Management
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "80%" }}
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Project Management Tools</span>
                  <span>60%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                Microsoft Project, Mural, Figma, Confluence, GitDocs, SharePoint
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "60%" }}
                    aria-valuenow={60}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Front-End Technologies</span>
                  <span>80%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                JavaScript, React, Angular, AngularJS, VueJs, NextJs, SASS, LESS
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "80%" }}
                    aria-valuenow={80}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Cloud Platforms</span>
                  <span>70%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                Google Cloud Platform, Google Firebase, Amazon Web Services
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "70%" }}
                    aria-valuenow={70}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Databases</span>
                  <span>65%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                Cloud SQL, FireStore, AlloyDB, MySQL, MSSQL, MongoDB
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "65%" }}
                    aria-valuenow={65}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Back-End Technologies</span>
                  <span>55%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                C#, ASP.NET, PHP, Java, Spring MVC 
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "55%" }}
                    aria-valuenow={55}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Testing Frameworks</span>
                  <span>70%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                TestCafe, Playwright, CucumberJs, Protractor, Karma, Mocha, Chai
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "70%" }}
                    aria-valuenow={70}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">DevOps</span>
                  <span>65%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                GitHub Actions, Jenkins, Bitbucket Pipelines, Groovy
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "65%" }}
                    aria-valuenow={65}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">Agile & Scrum</span>
                  <span>85%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                Jira, Rally, GitHub Projects
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "85%" }}
                    aria-valuenow={85}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>


            <div className="col-md-6">
              <div className="py-1">
                <div className="d-flex text-small fw-bolder">
                  <span className="me-auto">AI Tools</span>
                  <span>50%</span>
                </div>
                <div className="d-flex text-xsmall fw-lighter text-black-70">
                Google AI
                </div>
                <div className="progress my-1">
                  <div
                    className="progress-bar bg-primary"
                    role="progressbar"
                    data-aos="zoom-in-right"
                    data-aos-delay="100"
                    data-aos-anchor=".skills-section"
                    style={{ width: "50%" }}
                    aria-valuenow={50}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  ></div>
                </div>
              </div>
            </div>

            <div className="col-md-12 text-center">
              <div className="py-1">
                <div className="text-small fw-bolder">
                  <span className="me-auto">Others</span>
                </div>
                <div className="text-xsmall fw-lighter text-black-70">
                Semrush, Search Console, Google Analytics, Amplitude
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
