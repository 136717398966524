import React, { useEffect } from "react";
import "aos/dist/aos.css";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PageCover from "./components/PageCover";
import About from "./components/About";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Contact from "./components/Contact";
import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Header></Header>
      <div className="page-content">
        <div id="content">
          <PageCover></PageCover>
          <About></About>
          <Skills></Skills>
          <Experience></Experience>
          <Contact></Contact>
          <Footer></Footer>
        </div>
      </div>
      <div id="scrolltop">
        <a className="btn btn-secondary" href="#top">
          <span className="icon">
            <i className="fas fa-angle-up fa-x"></i>
          </span>
        </a>
      </div>
    </>
  );
}

export default App;
